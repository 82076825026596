.btn-secondary {
  color: var(--secondary-fg);
  background: var(--secondary-bg);
}
.btn-xs {
  font-size: 70%;
}
.btn-sm {
  font-size: 85%;
}
